import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse } from '../../types/api';
import { AuthToken } from '../../types/authentication/AuthToken';
import { EmailRegistrationData } from '../../types/api/EmailRegistrationData';

const URL = '/v2/admin/agency-register';

class AgencyRegistrationService {
  static registerEmail(
    data: EmailRegistrationData,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AuthToken>> {
    return axios.post(`${URL}/email`, data, config);
  }
}
export { AgencyRegistrationService };
