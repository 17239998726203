import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Grid, InputLabel, MenuItem, Select, SelectChangeEvent,
  TextField,
  Typography,
  TextFieldProps,
} from '@mui/material';

import { ReimbursementOrderService, User, UserVoucher, UserVoucherService } from 'us-web-services';
import toast from 'react-hot-toast';
import { DateTimePicker } from '@mui/x-date-pickers';
import DisplayService from '../../../util/DisplayService';
import PageStyles from '../../../styles/PageStyles';
import DateUtil from '../../../util/DateUtil';
import ReimbursementLookup from './ReimbursementLookup';

interface Props {
  user: User;
  history: {
    push(location: string): void;
  };
}

export default function AddReimbursement(props: Props) {
  const { history, user } = props;
  const { classes } = PageStyles();
  const [amount, setAmount] = useState(0);
  const [bookingId, setBookingId] = useState(null);
  const [userVouchers, setUserVouchers] = useState<UserVoucher[]>([]);
  const [selectedVouchers, setSelectedVouchers] = useState<number[]>([]);
  const [selectedCareTypeCategory, setSelectedCareTypeCategory] = useState<string>('');
  const [careTypeCategories, setCareTypeCategories] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const updateAmount = event => {
    setAmount(event.target.value);
  };

  const updateBookingId = event => {
    setBookingId(event.target.value);
  };

  const onVoucherSelect = (event: SelectChangeEvent<number[]>) => {
    setSelectedVouchers(event.target.value as number[]);
  };

  const onCareTypeCategorySelect = (event: SelectChangeEvent<string>) => {
    setSelectedCareTypeCategory(event.target.value);
  };

  const updateStartDate = (newDate: any) => {
    if (!newDate) {
      setStartDate(null);

      return;
    }

    const now = new Date();
    const selectedDate = new Date(newDate);

    if (selectedDate > now) {
      toast.error('Start date must be in the past');

      return;
    }

    const newStart = DateUtil.getDateTimeString(newDate.set({ seconds: 0 }));

    setStartDate(newStart);
  };

  const updateEndDate = (newDate: any) => {
    if (!newDate) {
      setEndDate(null);

      return;
    }

    const now = new Date();
    const selectedDate = new Date(newDate);
    const start = startDate ? new Date(startDate) : null;

    if (selectedDate > now) {
      toast.error('End date must be in the past');

      return;
    }

    if (start && selectedDate < start) {
      toast.error('End date cannot be before start date');

      return;
    }

    const newEnd = DateUtil.getDateTimeString(newDate.set({ seconds: 0 }));

    setEndDate(newEnd);
  };

  const showError = (error, defaultMessage) => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      defaultMessage,
    );

    toast.error(displayedError.message);
  };

  const reimburse = async () => {
    if (!amount) {
      toast.error('Amount must be greater than 0');

      return;
    }

    if (!selectedVouchers || selectedVouchers.length === 0) {
      toast.error('At least one user voucher must be selected');

      return;
    }

    if (!selectedCareTypeCategory) {
      toast.error('Care type category is required');

      return;
    }

    if (!startDate || !endDate) {
      toast.error('Both start and end dates are required');

      return;
    }

    const start = new Date(startDate);
    const end = new Date(endDate);
    const now = new Date();

    if (start > now || end > now) {
      toast.error('Both dates must be in the past');

      return;
    }

    if (end < start) {
      toast.error('End date cannot be before start date');

      return;
    }

    const startDateFormat = startDate;
    const endDateFormat = endDate;

    const itemVouchers = [];

    selectedVouchers.forEach(id => itemVouchers.push({ userVoucherId: id }));

    const data = {
      userId: user.id,
      total: amount,
      itemVouchers,
      itemBooking: {
        bookingId,
      },
      usOon: {
        startDate: startDateFormat,
        endDate: endDateFormat,
        careTypeCategory: selectedCareTypeCategory,
      },
    };

    try {
      await ReimbursementOrderService.reimburse(data);
      toast.success('Reimbursement succeeded');
      history.push(`/users/lookup?id=${user.id}`);
    } catch (error) {
      showError(error, 'Reimbursement failed');
    }
  };

  const getVouchers = useCallback(async () => {
    const voucherFilter = {
      params: {
        userId: user.id,
        limit: 100,
        expired: true,
      },
    };

    try {
      const vouchersResponse = (await UserVoucherService.getByFilter(voucherFilter)).data;

      setUserVouchers(vouchersResponse.data);
    } catch (error) {
      showError(error, 'Vouchers could not be loaded.');
      setUserVouchers([]);
    }
  }, [user.id]);

  const getCareTypeCategories = async () => {
    const categories: string[] = [
      'Children - In-home',
      'Children - Daycare',
      'Children - Camps',
      'Children - Preschool',
      'Tutoring',
      'Elder Care',
      'Pet Care',
      'Household Services',
    ];

    setCareTypeCategories(categories);
  };

  useEffect(() => {
    getVouchers();
    getCareTypeCategories();
  }, [getVouchers]);

  return (
    <div style={{ padding: 10 }}>
      <Typography variant='h6' gutterBottom>
        Reimburse {user.firstName} {user.lastName} (UID: {user.id})
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id='voucher-select-placeholder'>Select Vouchers</InputLabel>
            <Select
              id='voucher-select'
              value={selectedVouchers}
              onChange={onVoucherSelect}
              multiple
            >
              {userVouchers
                .filter(item => {
                  const isExpired = item.validUntil && new Date(item.validUntil) < new Date();
                  const isPercentDiscount = item.voucher.percentDiscount;

                  return (((!isExpired && !isPercentDiscount) || (isExpired && !isPercentDiscount))
                    && item.currentValue > 0);
                })
                .map(item => (
                  <MenuItem value={item.id} key={item.id}>
                    {`${item.voucher.code} - $${item.currentValue}
                      ${item.validUntil && new Date(item.validUntil) < new Date() ? ' (expired)' : ''}
                      ${item.active ? '' : ' (deactivated)'}`
                    }
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id='care-type-category-label'>Care type category</InputLabel>
            <Select
              labelId='care-type-category-label'
              id='care-type-category-select'
              value={selectedCareTypeCategory}
              onChange={onCareTypeCategorySelect}
              label='Care type category'
            >
              {careTypeCategories
                .map(item => (
                  <MenuItem value={item} key={item}>
                    {item}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            inputFormat={DateUtil.dateTimeReadable}
            minutesStep={15}
            value={startDate}
            onChange={updateStartDate}
            renderInput={(pickerProps: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                {...pickerProps}
                required
                fullWidth
                name='start'
                label='Start'
                id='start'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePicker
            inputFormat={DateUtil.dateTimeReadable}
            minutesStep={15}
            value={endDate}
            onChange={updateEndDate}
            renderInput={(pickerProps: JSX.IntrinsicAttributes & TextFieldProps) => (
              <TextField
                {...pickerProps}
                required
                fullWidth
                name='end'
                label='End'
                id='end'
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id='amount'
            label='Amount to Deduct'
            fullWidth
            value={amount}
            onChange={updateAmount}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id='bookingId'
            label='Booking Id'
            fullWidth
            value={bookingId}
            onChange={updateBookingId}
          />
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='primary'
          onClick={reimburse}
          className={classes.button}
        >
          Reimburse
        </Button>
        <br />
      </div>
      <Grid item xs={12} sx={{ mt: 4 }}>
        <ReimbursementLookup />
      </Grid>
    </div>
  );
}
