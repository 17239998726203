import React from 'react';
import { ButtonBase, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';
import { Booking, User } from 'us-web-services';

interface ButtonGroupProps {
  booking: Booking;
}

const useButtonGroupStyles = makeStyles<
void,
'focusVisible' | 'imageBackdrop' | 'imageMarked' | 'imageButton' | 'imageSrc' | 'image'
>()(
  (theme, _params, classes) => ({
    buttons: {
      display: 'flex',
      flexWrap: 'wrap',
      minWidth: 300,
      width: '100%',
    },
    image: {
      position: 'relative',
      height: 200,
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        height: 100,
      },
      [`&:hover, &.${classes.focusVisible}`]: {
        zIndex: 1,
        [`& .${classes.imageBackdrop}`]: {
          opacity: 0.15,
        },
        [`& .${classes.imageMarked}`]: {
          opacity: 0,
        },
        [`& .${classes.imageButton}`]: {
          border: '4px solid currentColor',
        },
      },
    },
    focusVisible: {},
    imageButton: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.white,
    },
    imageSrc: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundSize: 'cover',
      backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.palette.common.black,
      opacity: 0.4,
      transition: theme.transitions.create('opacity'),
    },
    imageMarked: {
      height: 3,
      width: 18,
      backgroundColor: theme.palette.common.white,
      position: 'absolute',
      bottom: -2,
      left: 'calc(50% - 9px)',
      transition: theme.transitions.create('opacity'),
    },
  }),
);

const UserButtonGroup = ({ booking }: ButtonGroupProps) => {
  const { classes } = useButtonGroupStyles();

  const CaregiverLink = React.forwardRef((refProps, ref) => (
    <Link
      // @ts-expect-error innerRef is valid
      innerRef={ref}
      to={`/users/lookup?id=${booking.caregiver.id}`}
      {...refProps}
    />
  ));

  const ParentLink = React.forwardRef((refProps, ref) => (
    <Link
      // @ts-expect-error innerRef is valid
      innerRef={ref}
      to={`/users/lookup?id=${booking.parent.id}`}
      {...refProps}
    />
  ));

  const renderPhotoButton = (
    user: User,
    userType: string,
    LinkComponent: any,
  ) => (
    <ButtonBase
      focusRipple
      key={user.id}
      className={classes.image}
      focusVisibleClassName={classes.focusVisible}
      style={{
        width: '50%',
      }}
      component={LinkComponent}
    >
      {user.pictureUrl && (
      <span
        className={classes.imageSrc}
        style={{
          backgroundImage: `url(${user.pictureUrl})`,
        }}
      />
      )}
      <span className={classes.imageBackdrop} />
      <span className={classes.imageButton}>
        <Typography
          component='span'
          variant='subtitle1'
          color='inherit'
          className={`${userType}_photo`}
        >
          {user.id} - {user.firstName} {user.lastName} ({userType})
          <span className={classes.imageMarked} />
        </Typography>
      </span>
    </ButtonBase>
  );

  return (
    <div className={classes.buttons}>
      {renderPhotoButton(booking.caregiver, 'Sitter', CaregiverLink)}
      {renderPhotoButton(booking.parent, 'Parent', ParentLink)}
    </div>
  );
};

export default UserButtonGroup;
