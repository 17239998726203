import React from 'react';
import { Typography } from '@mui/material';
import { Booking } from 'us-web-services';
import { makeStyles } from 'tss-react/mui';

interface ExtendedBookingDetailsProps {
  booking: Booking;
}

const useStyles = makeStyles<void, 'verticalStack'>()((theme) => ({
  verticalStack: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
}));

const ExtendedBookingDetails: React.FC<ExtendedBookingDetailsProps> = ({ booking }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.verticalStack}>
      <Typography variant='body1' color='textPrimary'>
        <strong>Family&apos;s name:</strong> {booking.parent.name}
      </Typography>

      <Typography variant='body1' color='textPrimary'>
        <strong>Kids&apos; names:</strong> {booking.parent.kids?.map((kid) => kid.name).join(', ')}
      </Typography>

      <Typography variant='body1' color='textPrimary'>
        <strong>Allergies:</strong> {booking.parent.kids?.map((kid) => kid.allergies || 'None').join(', ')}
      </Typography>

      <Typography variant='body1' color='textPrimary'>
        <strong>Special needs:</strong> {booking.parent.kids
          .flatMap((kid) => kid.experiences.map((exp) => exp.specialExperience?.name))
          .filter(Boolean)
          .join(', ')}
      </Typography>

      <Typography variant='body1' color='textPrimary'>
        <strong>Number of kids:</strong> {booking.numberOfChildren}
      </Typography>

      <Typography variant='body1' color='textPrimary'>
        <strong>Kids&apos; ages:</strong> {booking.kidsAges}
      </Typography>

      <Typography variant='body1' color='textPrimary'>
        <strong>Phone:</strong> {booking.parent.phone || 'Not provided'}
      </Typography>

      <Typography variant='body1' color='textPrimary'>
        <strong>Job description:</strong> {booking.description}
      </Typography>

      <Typography variant='body1' color='textPrimary'>
        <strong>Job includes:</strong> {booking.includes.map((include) => include.label).join(', ')}
      </Typography>

      <Typography variant='body1' color='textPrimary'>
        <strong>Deal sweeteners:</strong> {booking.sweeteners.map((sweetener) => sweetener.label).join(', ')}
      </Typography>

      <Typography variant='body1' color='textPrimary'>
        <strong>Agency user note:</strong> {booking.caregiverNote || 'None'}
      </Typography>
    </div>
  );
};

export default ExtendedBookingDetails;
