import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from '@mui/material';
import { User } from 'us-web-services';
import useAuthentication from '../../store/useAuthentication';

interface Props extends RouteComponentProps {
  user: User;
}

function LookupTabs(props: Props) {
  const authenticationState = useAuthentication()[0];
  const authenticatedUser = authenticationState.authentication.user;
  const { history, location, user } = props;
  const userId = user.id;
  const isPaymentAdmin = authenticatedUser.roles.filter(role => role.code === 'PAYMENT_ADMIN').length > 0;
  const userDetails = () => {
    history.push(`/users/lookup?id=${userId}`);
  };

  const userReviews = () => {
    history.push(`/users/reviews?id=${userId}`);
  };

  const userTransactions = () => {
    history.push(`/users/transactions?id=${userId}`);
  };

  const userPlans = () => {
    history.push(`/users/plans?id=${userId}`);
  };

  const userReimbursement = () => {
    history.push(`/users/reimbursement?id=${userId}`);
  };

  const getVariant = url =>
    location.pathname === url ? 'outlined' : 'contained';

  const isAgency = () => !user.parent && !user.sitter;

  return (
    <div style={{ margin: '40px 16px 50px 0' }}>
      <div style={{ marginBottom: 20 }}>
        <Button
          size='small'
          variant={getVariant('/users/lookup')}
          color='secondary'
          onClick={userDetails}
          style={{ marginRight: 10 }}
        >
          Details
        </Button>
        {!isAgency() &&
          (
            <Button
              size='small'
              variant={getVariant('/users/reviews')}
              color='secondary'
              onClick={userReviews}
              style={{ marginRight: 10 }}
            >
              Reviews
            </Button>
          )
        }
        <Button
          size='small'
          variant={getVariant('/users/transactions')}
          color='secondary'
          onClick={userTransactions}
          style={{ marginRight: 10 }}
        >
          Transactions
        </Button>
        {!isAgency() &&
          (
            <Button
              size='small'
              variant={getVariant('/users/plans')}
              color='secondary'
              onClick={userPlans}
              style={{ marginRight: 10 }}
            >
              Plans
            </Button>
          )
        }
        {user.parent && isPaymentAdmin &&
          (
            <Button
              size='small'
              variant={getVariant('/users/reimbursement')}
              color='secondary'
              onClick={userReimbursement}
            >
              Reimbursement
            </Button>
          )
        }
      </div>
    </div>
  );
}

export default withRouter(LookupTabs);
