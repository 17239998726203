import React from 'react';
import { Route } from 'react-router-dom';
import AffiliationLookup from './components/groups/AffiliationLookup';
import AffiliationEdit from './components/groups/AffiliationEdit';
import AffiliationCreate from './components/groups/AffiliationCreate';
import AffiliationMerge from './components/groups/AffiliationMerge';
import ApiProfiler from './components/qa-tools/ApiProfiler';
import BatchUsers from './components/users/BatchUsers';
import BookingEdit from './components/bookings/BookingEdit';
import BookingGroupEdit from './components/bookings/BookingGroupEdit';
import BookingLookup from './components/bookings/BookingLookup';
import CityLookup from './components/seo/CityLookup';
import CitySeoContentEdit from './components/seo/CitySeoContentEdit';
import CollegeLookup from './components/groups/CollegeLookup';
import CollegeEdit from './components/groups/CollegeEdit';
import CollegeCreate from './components/groups/CollegeCreate';
import {
  CompanyBenefitCreate,
  CompanyBenefitEdit,
  CompanyCreate,
  CompanyEdit,
  CompanyLookup,
} from './components/business-development/companies';
import DuplicateSitters from './components/duplicate-sitters/DuplicateSitters';
import EmployeeAdd from './components/business-development/employees/EmployeeAdd';
import EmployeeLookup from './components/business-development/employees/EmployeeLookup';
import JobModeration from './components/job-moderation/JobModeration';
import LocalGroupLookup from './components/groups/LocalGroupLookup';
import LocalGroupEdit from './components/groups/LocalGroupEdit';
import LocalGroupCreate from './components/groups/LocalGroupCreate';
import LocalGroupMerge from './components/groups/LocalGroupMerge';
import MasterServiceAreaEdit from './components/master-service-area/MasterServiceAreaEdit';
import MasterServiceAreaLookup from './components/master-service-area/MasterServiceAreaLookup';
import MasterServiceAreaTierLookup from './components/master-service-area/tiers/MasterServiceAreaTierLookup';
import MasterServiceAreaTierEdit from './components/master-service-area/tiers/MasterServiceAreaTierEdit';
import OrderLookup from './components/orders/OrderLookup';
import OrderPayout from './components/orders/OrderPayout';
import PartnerLookup from './components/partners/PartnerLookup';
import PartnerCreate from './components/partners/PartnerCreate';
import PartnerEdit from './components/partners/PartnerEdit';
import PressLookup from './components/marketing/PressLookup';
import PressEdit from './components/marketing/PressEdit';
import SchoolLookup from './components/groups/SchoolLookup';
import SchoolEdit from './components/groups/SchoolEdit';
import SchoolCreate from './components/groups/SchoolCreate';
import SchoolMerge from './components/groups/SchoolMerge';
import StateLookup from './components/seo/StateLookup';
import StateSeoContentEdit from './components/seo/StateSeoContentEdit';
import TagLookup from './components/users/TagLookup';
import TagEdit from './components/users/TagEdit';
import { UserEdit, UserLookup } from './components/users';
import UserTransactions from './components/users/UserTransactions';
import UserReviews from './components/users/UserReviews';
import UserPlans from './components/users/plans/UserPlans';
import {
  VoucherUpdate,
  VoucherNotFound,
  VoucherLookup,
} from './components/vouchers';
import VideoModeration from './components/videos/VideoModeration';
import WinbackCodeLookup from './components/winback-code/WinbackCodeLookup';
import WinbackCodeEdit from './components/winback-code/WinbackCodeEdit';
import ZipCodeEdit from './components/master-service-area/zip-codes/ZipCodeEdit';
import ZipCodeLookup from './components/master-service-area/zip-codes/ZipCodeLookup';
import MasterServiceAreaPlanPriceEdit from './components/master-service-area/MasterServiceAreaPlanPriceEdit';
import BatchMailchimp from './components/users/mailchimp/BatchMailchimp';
import ResetUserPasswords from './components/users/ResetUserPasswords';
import SSOTest from './components/qa-tools/SSOTest';
import UserReimbursement from './components/users/reimbursements/UserReimbursement';
import MembershipRenewal from './components/qa-tools/MembershipRenewal';
import OrderPayoutDelay from './components/orders/OrderPayoutDelay';

const Routes = (): JSX.Element => (
  <>
    <Route exact path='/' component={UserLookup} />
    <Route exact path='/affiliations' component={AffiliationLookup} />
    <Route path='/affiliations/:id' component={AffiliationEdit} />
    <Route path='/affiliations-merge' component={AffiliationMerge} />
    <Route path='/affiliation-create' component={AffiliationCreate} />
    <Route path='/api-profiler' component={ApiProfiler} />
    <Route path='/batch-mailchimp' component={BatchMailchimp} />
    <Route path='/bookings/lookup' component={BookingLookup} />
    <Route path='/bookings/edit/:id' component={BookingEdit} />
    <Route path='/booking-groups/edit/:id' component={BookingGroupEdit} />
    <Route exact path='/colleges' component={CollegeLookup} />
    <Route path='/colleges/:id' component={CollegeEdit} />
    <Route path='/college-create' component={CollegeCreate} />
    <Route exact path='/companies' component={CompanyLookup} />
    <Route exact path='/companies/:id' component={CompanyEdit} />
    <Route
      exact
      path='/company-benefits/:benefitId'
      component={CompanyBenefitEdit}
    />
    <Route
      exact
      path='/company-benefit-create/:companyId'
      component={CompanyBenefitCreate}
    />
    <Route exact path='/company-create' component={CompanyCreate} />
    <Route exact path='/company-employees' component={EmployeeLookup} />
    <Route exact path='/company-employees/add' component={EmployeeAdd} />
    <Route path='/duplicate-sitters' component={DuplicateSitters} />
    <Route exact path='/job-moderation' component={JobModeration} />
    <Route exact path='/local-groups' component={LocalGroupLookup} />
    <Route path='/local-groups/:id' component={LocalGroupEdit} />
    <Route path='/local-groups-merge' component={LocalGroupMerge} />
    <Route path='/local-group-create' component={LocalGroupCreate} />
    <Route
      exact
      path='/master-service-areas'
      component={MasterServiceAreaLookup}
    />
    <Route
      exact
      path='/master-service-areas/:id'
      component={MasterServiceAreaEdit}
    />
    <Route
      exact
      path='/master-service-areas/:masterServiceAreaId/prices/:id'
      component={MasterServiceAreaPlanPriceEdit}
    />
    <Route
      exact
      path='/master-service-areas/:masterServiceAreaId/price-create'
      component={MasterServiceAreaPlanPriceEdit}
    />
    <Route
      exact
      path='/master-service-area-create'
      component={MasterServiceAreaEdit}
    />
    <Route
      exact
      path='/master-service-area-tiers'
      component={MasterServiceAreaTierLookup}
    />
    <Route
      exact
      path='/master-service-area-tiers/:id'
      component={MasterServiceAreaTierEdit}
    />
    <Route
      exact
      path='/master-service-area-tier-create'
      component={MasterServiceAreaTierEdit}
    />
    <Route path='/membership-renewal' component={MembershipRenewal} />
    <Route path='/orders/lookup' component={OrderLookup} />
    <Route path='/orders/payout' component={OrderPayout} />
    <Route path='/orders/payout-delay' component={OrderPayoutDelay} />
    <Route exact path='/partners' component={PartnerLookup} />
    <Route exact path='/partners/:id' component={PartnerEdit} />
    <Route exact path='/partner-create' component={PartnerCreate} />
    <Route exact path='/press' component={PressLookup} />
    <Route path='/press/:id' component={PressEdit} />
    <Route exact path='/press-create' component={PressEdit} />
    <Route path='/reset-user-passwords' component={ResetUserPasswords} />
    <Route exact path='/schools' component={SchoolLookup} />
    <Route path='/schools/:id' component={SchoolEdit} />
    <Route path='/schools-merge' component={SchoolMerge} />
    <Route path='/school-create' component={SchoolCreate} />
    <Route exact path='/seo/cities' component={CityLookup} />
    <Route path='/seo/cities/:id' component={CitySeoContentEdit} />
    <Route exact path='/seo/states' component={StateLookup} />
    <Route exact path='/seo/states/:state' component={StateSeoContentEdit} />
    <Route path='/sso-test' component={SSOTest} />
    <Route exact path='/tags' component={TagLookup} />
    <Route path='/tags/:id' component={TagEdit} />
    <Route exact path='/tag-create' component={TagEdit} />
    <Route path='/users/batch' component={BatchUsers} />
    <Route path='/users/edit/:id' component={UserEdit} />
    <Route path='/users/lookup' component={UserLookup} />
    <Route path='/users/plans' component={UserPlans} />
    <Route path='/users/reimbursement' component={UserReimbursement} />
    <Route path='/users/reviews' component={UserReviews} />
    <Route path='/users/transactions' component={UserTransactions} />
    <Route exact path='/video-moderation' component={VideoModeration} />
    <Route exact path='/vouchers' component={VoucherLookup} />
    <Route exact path='/vouchers/:id' component={VoucherUpdate} />
    <Route exact path='/voucher-create' component={VoucherUpdate} />
    <Route exact path='/voucher-not-found' component={VoucherNotFound} />
    <Route exact path='/winback-codes' component={WinbackCodeLookup} />
    <Route exact path='/winback-codes/:id' component={WinbackCodeEdit} />
    <Route exact path='/winback-code-create' component={WinbackCodeEdit} />
    <Route exact path='/zip-codes' component={ZipCodeLookup} />
    <Route exact path='/zip-codes/:id' component={ZipCodeEdit} />
    <Route exact path='/zip-code-create' component={ZipCodeEdit} />
  </>
);

export default Routes;
