import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormLabel, Grid, Paper, TextField, Typography } from '@mui/material';
import { AdminSystemSetting, AdminSystemSettingService } from 'us-web-services';
import toast from 'react-hot-toast';
import useAuthentication from '../../store/useAuthentication';
import PageStyles from '../../styles/PageStyles';

export default function OrderPayoutDelay() {
  const { classes } = PageStyles();
  const authenticationState = useAuthentication()[0];
  const authenticatedUser = authenticationState.authentication.user;
  const [queueSetting, setQueueSetting] = useState<AdminSystemSetting>({});
  const [queueDaysSetting, setQueueDaysSetting] = useState<AdminSystemSetting>({
    value: '0',
  });

  const isPaymentAdmin = authenticatedUser.roles.filter(role => role.code === 'PAYMENT_ADMIN').length > 0;

  const getQueueSetting = async () => {
    const config = {
      params: {
        name: 'booking_order_queue',
      },
    };
    const response = await AdminSystemSettingService.findByFilter(config);

    setQueueSetting(response.data.data[0]);
  };

  const getQueueDaysSetting = async () => {
    const config = {
      params: {
        name: 'booking_order_queue_clear_days',
      },
    };
    const response = await AdminSystemSettingService.findByFilter(config);

    setQueueDaysSetting(response.data.data[0]);
  };

  const togglePayoutDelay = () => {
    if (queueSetting.value === 'true') {
      queueSetting.value = 'false';
    } else {
      queueSetting.value = 'true';
    }

    setQueueSetting({
      ...queueSetting,
    });
  };

  const updateQueueDays = (event) => {
    queueDaysSetting.value = event.target.value;
    setQueueDaysSetting({
      ...queueDaysSetting,
    });
  };

  const save = async () => {
    try {
      await AdminSystemSettingService.update(queueSetting.id, queueSetting);
      await AdminSystemSettingService.update(queueDaysSetting.id, queueDaysSetting);

      toast.success('Payout delay settings successfully updated');
    } catch (e) {
      toast.error(e.response.data.errors[0].message);
    }
  };

  useEffect(() => {
    getQueueSetting();
    getQueueDaysSetting();
  }, []);

  return (
    <>
      { isPaymentAdmin ?
        <Paper className={classes.paper}>
          <Typography variant='h5' gutterBottom>
            Delay Payouts
          </Typography>
          <p>
            Turns on or off delaying payouts by a specified number of days
          </p>
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormLabel component='legend'>Enable Payout Delay?</FormLabel>
                <Checkbox
                  checked={queueSetting.value === 'true'}
                  onChange={togglePayoutDelay}
                  inputProps={{
                    'aria-label': 'primary checkbox',
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id='days'
                  label='Number of Days to Delay'
                  onChange={updateQueueDays}
                  required
                  type='number'
                  value={queueDaysSetting.value}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={save}
                  style={{ marginTop: '10px' }}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>
        :
        <Typography variant='h6' gutterBottom>
          You do not have permission to use this tool.
        </Typography>
      }
    </>
  );
}
