import React, { useEffect } from 'react';
import classNames from 'classnames';
import { withStyles } from 'tss-react/mui';
import {
  ClassNameMap,
  Collapse,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  PaperProps,
} from '@mui/material';
import {
  AccountBalance,
  Apartment,
  AttachMoney,
  Book,
  BugReport,
  Business,
  LockClock,
  Computer,
  Code,
  Email,
  ExpandLess,
  ExpandMore,
  Flag,
  Group,
  GroupWork,
  Key,
  Language,
  ListAlt,
  LocalAtm,
  LocalLibrary,
  LocalOffer,
  LocationCity,
  LocationOn,
  LockOpen,
  MapOutlined,
  MenuBook,
  Note,
  Password,
  People,
  PlusOne,
  PowerSettingsNew,
  Receipt,
  School,
  Subject,
  VideoCall,
  Work,
} from '@mui/icons-material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppNavigationItem } from '../components/navigation';
import { NavigationItems } from '../constants';
import useAuthentication from '../store/useAuthentication';
import { ReactComponent as Logo } from '../img/logo_white.svg';

const styles = (theme, _params, classes) => ({
  categoryHeader: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  itemCategory: {
    backgroundColor: theme.palette.common.black,
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: 16,
    paddingBottom: 16,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  itemActionable: {
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
  itemPrimary: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize,
    [`&.${classes.textDense}`]: {
      fontSize: theme.typography.fontSize,
    },
  },
  textDense: {},
  divider: {
    marginTop: theme.spacing(1) * 2,
  },
  logout: {
    cursor: 'pointer',
  },
  token: {
    cursor: 'pointer',
    position: 'absolute',
    bottom: '10px',
  },
});

interface Props extends RouteComponentProps {
  classes?: ClassNameMap;
  open?: boolean;
  onClose?: () => void;
  paperProps?: PaperProps;
  variant: 'permanent' | 'persistent' | 'temporary';
}

function Navigator(props: Props) {
  const { classes, location, open = false, onClose, paperProps = {}, variant } = props;

  const [authenticationState, authenticationActions] = useAuthentication();
  const authenticatedUser = authenticationState.authentication.user;
  const isPaymentAdmin = authenticatedUser.roles.filter(role => role.code === 'PAYMENT_ADMIN').length > 0;
  const isSecurityAdmin = authenticatedUser.roles.filter(role => role.code === 'SECURITY_ADMIN').length > 0;

  const [openId, setOpenId] = React.useState('Users');

  const logout = () => {
    authenticationActions.authentication.set({});
  };

  const copyAdminToken = () => {
    const { token } = authenticationState.authentication;

    navigator.clipboard.writeText(`Bearer ${token}`);
  };

  const userCategories = {
    id: 'Users',
    icon: <People />,
    children: [
      {
        id: NavigationItems.USER_LOOKUP,
        icon: <People />,
        url: '/users/lookup',
      },
      {
        id: NavigationItems.BATCH_USER_ACTIONS,
        icon: <GroupWork />,
        url: '/users/batch',
      },
      {
        id: NavigationItems.VIDEO_MODERATION,
        icon: <VideoCall />,
        url: '/video-moderation',
      },
      {
        id: NavigationItems.TAGS,
        icon: <LocalOffer />,
        url: '/tags',
      },
      {
        id: NavigationItems.DUPLICATE_SITTERS,
        icon: <People />,
        url: '/duplicate-sitters',
      },
      {
        id: NavigationItems.JOB_MODERATION,
        icon: <Note />,
        url: '/job-moderation',
      },
      {
        id: NavigationItems.BATCH_MAILCHIMP,
        icon: <Email />,
        url: '/batch-mailchimp',
      },
    ],
  };

  if (isSecurityAdmin) {
    userCategories.children.push({
      id: NavigationItems.RESET_USER_PASSWORDS,
      icon: <Password />,
      url: '/reset-user-passwords',
    });
  }

  const bookingCategories = {
    id: 'Bookings',
    icon: <Book />,
    children: [
      {
        id: NavigationItems.BOOKING_LOOKUP,
        icon: <Book />,
        url: '/bookings/lookup',
      },
    ],
  };

  const orderCategories = {
    id: 'Orders',
    icon: <AttachMoney />,
    children: [
      {
        id: NavigationItems.ORDER_LOOKUP,
        icon: <AccountBalance />,
        url: '/orders/lookup',
      },
    ],
  };

  if (isPaymentAdmin) {
    orderCategories.children.push({
      id: NavigationItems.ORDER_PAYOUT,
      icon: <LocalAtm />,
      url: '/orders/payout',
    });
    orderCategories.children.push({
      id: NavigationItems.ORDER_PAYOUT_DELAY,
      icon: <LockClock />,
      url: '/orders/payout-delay',
    });
  }

  const marketingCategories = {
    id: 'Marketing',
    icon: <Subject />,
    children: [
      {
        id: NavigationItems.SEO_STATE,
        icon: <Flag />,
        url: '/seo/states',
      },
      {
        id: NavigationItems.SEO_CITY,
        icon: <Apartment />,
        url: '/seo/cities',
      },
      {
        id: NavigationItems.PRESS,
        icon: <MenuBook />,
        url: '/press',
      },
      {
        id: NavigationItems.WINBACK_CODE,
        icon: <Code />,
        url: '/winback-codes',
      },
      {
        id: NavigationItems.MASTER_SERVICE_AREAS,
        icon: <MapOutlined />,
        url: '/master-service-areas',
      },
    ],
  };

  const msaCategories = {
    id: 'Master Service Areas',
    icon: <Language />,
    children: [
      {
        id: NavigationItems.MASTER_SERVICE_AREAS,
        icon: <Language />,
        url: '/master-service-areas',
      },
      {
        id: NavigationItems.MASTER_SERVICE_AREA_TIERS,
        icon: <Apartment />,
        url: '/master-service-area-tiers',
      },
      {
        id: NavigationItems.ZIP_CODE_LOOKUP,
        icon: <LocationOn />,
        url: '/zip-codes',
      },
    ],
  };

  const voucherCategories = {
    id: 'Vouchers',
    icon: <Receipt />,
    children: [
      {
        id: NavigationItems.VOUCHER_LOOKUP,
        icon: <ListAlt />,
        url: '/vouchers',
      },
      {
        id: NavigationItems.VOUCHER_ADD,
        icon: <PlusOne />,
        url: '/voucher-create',
      },
      {
        id: NavigationItems.PARTNERS,
        icon: <ListAlt />,
        url: '/partners',
      },
    ],
  };

  const groupCategories = {
    id: 'Groups',
    icon: <GroupWork />,
    children: [
      {
        id: NavigationItems.GROUPS_AFFILIATIONS,
        icon: <Group />,
        url: '/affiliations',
      },
      {
        id: NavigationItems.GROUPS_SCHOOLS,
        icon: <LocalLibrary />,
        url: '/schools',
      },
      {
        id: NavigationItems.GROUPS_LOCAL_GROUPS,
        icon: <LocationCity />,
        url: '/local-groups',
      },
      {
        id: NavigationItems.GROUPS_COLLEGES,
        icon: <School />,
        url: '/colleges',
      },
    ],
  };

  const qaCategories = {
    id: 'QA Tools',
    icon: <BugReport />,
    children: [
      {
        id: NavigationItems.QA_API_PROFILER,
        icon: <Computer />,
        url: '/api-profiler',
      },
      {
        id: NavigationItems.QA_SSO_TEST,
        icon: <LockOpen />,
        url: '/sso-test',
      },
      {
        id: NavigationItems.QA_MEMBERSHIP_RENEWAL,
        icon: <AttachMoney />,
        url: '/membership-renewal',
      },
    ],
  };

  const bizDevCategories = {
    id: 'Business Dev',
    icon: <Business />,
    children: [
      {
        id: NavigationItems.COMPANIES,
        icon: <Work />,
        url: '/companies',
      },
      {
        id: NavigationItems.EMPLOYEES,
        icon: <People />,
        url: '/company-employees',
      },
    ],
  };

  const nav = [
    userCategories,
    bookingCategories,
    orderCategories,
    marketingCategories,
    msaCategories,
    voucherCategories,
    groupCategories,
    qaCategories,
    bizDevCategories,
  ];

  const isOpen = id => id === openId;

  const sectionClick = id => {
    if (openId === id) {
      setOpenId('');
    } else {
      setOpenId(id);
    }
  };

  useEffect(() => {
    nav.forEach(item => {
      item.children.forEach(child => {
        if (child.url === location.pathname) {
          setOpenId(item.id);
        }
      });
    });
  }, [location.pathname]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      PaperProps={paperProps}
      variant={variant}
    >
      <List disablePadding>
        <ListItem
          className={classNames(
            classes.firebase,
            classes.item,
            classes.itemCategory,
          )}
        >
          <Logo />
        </ListItem>
        {nav.map(({ id, icon, children }) => (
          <React.Fragment key={id}>
            <ListItem
              button
              onClick={() => sectionClick(id)}
              className={classNames(
                classes.firebase,
                classes.item,
                classes.itemCategory,
              )}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText
                primary={id}
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              />
              {isOpen(id) ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={isOpen(id)} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {children.map(({ id: text, icon: childIcon, url }) => (
                  <AppNavigationItem
                    key={text}
                    text={text}
                    icon={childIcon}
                    url={url}
                    active={location.pathname === url}
                  />
                ))}
              </List>
            </Collapse>
          </React.Fragment>
        ))}
        <React.Fragment key='Logout'>
          <ListItem className={classes.categoryHeader}>
            <ListItemText
              classes={{
                primary: classes.categoryHeaderPrimary,
              }}
            >
              Logout
            </ListItemText>
          </ListItem>
          <ListItem
            button
            dense
            className={classNames(
              classes.item,
              classes.itemActionable,
              classes.logout,
            )}
            onClick={logout}
          >
            <ListItemIcon>
              <PowerSettingsNew />
            </ListItemIcon>
            <ListItemText
              classes={{
                primary: classes.itemPrimary,
              }}
            >
              Logout
            </ListItemText>
          </ListItem>
          <Divider className={classes.divider} />

        </React.Fragment>
      </List>
      <ListItem
        button
        dense
        className={classNames(
          classes.item,
          classes.itemActionable,
          classes.token,
        )}
        onClick={copyAdminToken}
      >
        <ListItemIcon>
          <Key />
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classes.itemPrimary,
          }}
        >
          Copy Admin Token
        </ListItemText>
      </ListItem>
    </Drawer>
  );
}

export default withRouter(withStyles(Navigator, styles));
