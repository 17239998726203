import React, { useMemo, useState } from 'react';

import { BookingNoteService } from 'us-web-services';
import { CreateModal, Table } from '../../common/Table';
import { TableInstance } from '../../common/Table/Table';

interface Props {
  bookingId: number;
  adminUserId: number;
}

function BookingAdminNotes(props: Props) {
  const { adminUserId, bookingId } = props;
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const tableRef = React.useRef<TableInstance>();
  const COLUMNS = useMemo(
    () => [
      {
        id: 'adminNotes',
        header: 'Internal Admin Notes',
        columns: [
          {
            header: 'Note',
            accessorKey: 'note',
            muiTableBodyCellEditTextFieldProps: {
              multiline: true,
            },
            muiTableHeadCellProps: {
              style: { minWidth: 300 }, // Set the minimum width for the Note column header
            },
            muiTableBodyCellProps: {
              style: { minWidth: 300 }, // Set the minimum width for the Note column cells
            },
            enableEditing: (rowData) => rowData.original.createdBy?.id === adminUserId,
          },
          {
            header: 'Created',
            accessorKey: 'createdDate',
            enableEditing: false,
          },
          {
            header: 'Created By',
            accessorFn: rowData => rowData && `${rowData.createdBy.firstName} ${rowData.createdBy.lastName}`,
            enableEditing: false,
          },
        ],
      },
    ],
    [],
  );

  const updateNotes = () => tableRef.current?.fetchData();

  const addNote = async values => {
    const noteData = {
      bookingId,
      createdBy: {
        id: adminUserId,
      },
      note: values.note,
    };

    await BookingNoteService.create(noteData);
    updateNotes();
  };

  const editNote = async ({ row, values }) => {
    await BookingNoteService.update(row.original.id, {
      id: row.original.id,
      note: values.note,
    });
    updateNotes();
  };

  const params = () => [
    {
      key: 'bookingId',
      value: bookingId.toString(),
    },
  ];

  return (
    <div style={{ paddingTop: 10 }}>
      <Table
        ref={tableRef}
        columns={COLUMNS}
        retrieveData={BookingNoteService.getByFilter}
        editAction={editNote}
        enableInlineEditing
        params={params}
        alwaysApplyParams
        addAction={() => setCreateModalOpen(true)}
      />
      <CreateModal
        columns={COLUMNS}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={addNote}
        title='Add Note'
        buttonText='Add'
      />
    </div>
  );
}

export default BookingAdminNotes;
