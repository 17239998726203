import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../../types/api';
import { AgencyAvailability } from '../../types/agency/AgencyAvailability';

const URL = '/v2/agency-availabilities';

class AgencyAvailabilityService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<AgencyAvailability>> {
    return axios.get(URL, config);
  }

  static create(
    data: AgencyAvailability,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AgencyAvailability>> {
    return axios.post(URL, data, config);
  }

  static patch(
    id: number,
    data: AgencyAvailability,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AgencyAvailability>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }

  static delete(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AgencyAvailability>> {
    return axios.delete(`${URL}/${id}`, config);
  }
}
export { AgencyAvailabilityService };
