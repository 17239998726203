export default {
  BATCH_MAILCHIMP: 'Batch Update Mailchimp Subscriptions',
  BATCH_USER_ACTIONS: 'Batch User Actions',
  BOOKING_LOOKUP: 'Booking Lookup',
  COMPANIES: 'Companies',
  DUPLICATE_SITTERS: 'Resolve Duplicate Sitters',
  EMPLOYEES: 'Employees',
  GROUPS_AFFILIATIONS: 'Affiliations',
  GROUPS_COLLEGES: 'Colleges',
  GROUPS_LOCAL_GROUPS: 'Local Groups',
  GROUPS_SCHOOLS: 'Schools',
  JOB_MODERATION: 'Job Moderation',
  MASTER_SERVICE_AREA_TIERS: 'Master Service Area Tiers',
  MASTER_SERVICE_AREAS: 'Master Service Areas',
  ORDER_LOOKUP: 'Order Lookup',
  ORDER_PAYOUT: 'Send Payout',
  ORDER_PAYOUT_DELAY: 'Delay Payouts',
  PARTNERS: 'Partners',
  PRESS: 'Press',
  QA_API_PROFILER: 'API Profiler',
  QA_SSO_TEST: 'SSO Test',
  QA_MEMBERSHIP_RENEWAL: 'Membership Renewal',
  RESET_USER_PASSWORDS: 'Reset User Passwords',
  SEO_CITY: 'Cities SEO',
  SEO_STATE: 'States SEO',
  TAGS: 'Tags',
  USER_LOOKUP: 'User Lookup',
  VIDEO_MODERATION: 'Video Moderation',
  VOUCHER_ADD: 'Add Voucher',
  VOUCHER_LOOKUP: 'Voucher Lookup',
  WINBACK_CODE: 'Winback Codes',
  ZIP_CODE_LOOKUP: 'Zip Codes',
};
