import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import moment from 'moment';
import { Booking } from 'us-web-services';
import { useAuthentication } from 'src/store';
import UserButtonGroup from './components/UserButtonGroup';
import ExtendedBookingDetails from './components/ExtendedBookingDetails';
import BookingAdminNotes from './components/BookingAdminNotes';

interface Props extends RouteComponentProps {
  booking: Booking;
}

const useStyles = makeStyles<void, 'content' | 'bookingLink' | 'titleBar'>()((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
  },
  bookingLink: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: theme.spacing(1),
    paddingTop: theme.spacing(4),
  },
  content: {
    flex: '1 0 auto',
  },
  titleBar: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '20px',
  },
  verticalStack: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    paddingTop: theme.spacing(4),
  },
}));

// Function to format the address into a clean, readable string
const formatAddress = (address: {
  street?: string;
  street2?: string;
  neighborhood?: string;
  city?: string;
  state?: string;
  zip?: string;
}) => {
  const { street, street2, city, state, zip } = address;

  return (
    <Typography variant='body1' color='textPrimary'>
      <strong>Street Address:</strong> {`${street}${street2 ? `, ${street2}` : ''}, ${city}, ${state} ${zip}`}
    </Typography>
  );
};

const AGENCY_TYPE = 2;

function BookingCard(props: Props) {
  const { booking } = props;
  const { classes } = useStyles();
  const authenticationState = useAuthentication()[0];

  return (
    <Card className={classes.card}>

      <UserButtonGroup booking={booking} />
      <CardContent className={classes.content}>
        <div className={classes.bookingLink}>
          <Typography className={classes.titleBar} component='h5' variant='h5'>
            <span>ID: {booking.id}</span>
            <span>Status: {booking.status.code}</span>
          </Typography>
          <div className={classes.verticalStack}>
            <Typography variant='h5' color='textPrimary'>
              <strong>Booking Details</strong>
            </Typography>

            <Typography variant='body1' color='textPrimary'>
              <strong>Start Date/Time:</strong> {moment(booking.start).format('LLL')}
            </Typography>

            <Typography variant='body1' color='textPrimary'>
              <strong>End Date/Time:</strong> {moment(booking.end).format('LLL')}
            </Typography>

            {formatAddress(booking.address)}

            {booking.caregiverType === AGENCY_TYPE && (
            <ExtendedBookingDetails booking={booking} />
            )}

            <div className={classes.buttons}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => { window.location.href = `/bookings/edit/${booking.id}`; }}
              >
                Edit Booking
              </Button>
              {booking.group.id > 0 ? (
                <Button
                  variant='contained'
                  color='secondary'
                  onClick={() => { window.location.href = `/booking-groups/edit/${booking.group.id}`; }}
                >
                  Edit Booking Group
                </Button>
              ) : (
                ''
              )}
            </div>

          </div>
        </div>
      </CardContent>
      <BookingAdminNotes
        adminUserId={authenticationState.authentication.user.id}
        bookingId={booking.id}
      />
    </Card>
  );
}

export default withRouter(BookingCard);
