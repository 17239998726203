import React, { useEffect, useState } from 'react';
import { Button, Paper } from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import toast from 'react-hot-toast';
import moment from 'moment';

import { Booking, BookingService } from 'us-web-services';
import BookingEditForm from './BookingEditForm';
import useAuthentication from '../../store/useAuthentication';
import PageStyles from '../../styles/PageStyles';
import DateUtil from '../../util/DateUtil';
import DisplayService from '../../util/DisplayService';

function BookingEdit(props: RouteComponentProps<{ id: string }>) {
  const authenticationState = useAuthentication()[0];
  const authenticatedUser = authenticationState.authentication.user;
  const { classes } = PageStyles();
  const { match, history } = props;
  const [booking, setBooking] = useState<Booking>(undefined);

  useEffect(() => {
    const newBookingId = Number.parseInt(match.params.id, 10);

    if (!booking?.id || newBookingId !== booking.id) {
      const getBooking = async () => {
        const bookingParams = {
          params: {
            fields: 'adminCancellationReason,adminCancellationNote',
            userId: authenticatedUser.id,
          },
        };
        const bookingResponse = await BookingService.get(newBookingId, bookingParams);
        const bookingData = bookingResponse.data.data;

        setBooking(bookingData);
      };

      getBooking();
    }
  }, [authenticatedUser.id, match.params.id]);

  const cancelEdit = async () => {
    history.push(`/bookings/lookup?id=${booking.id}`);
  };

  const parentAction = status =>
    status === 'parent_cancel' ||
    status === 'parent_reschedule' ||
    status === 'parent_confirm_payment';

  const showError = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'There was an unexpected error updating the booking.',
    );

    toast.error(displayedError.message);
  };

  const rejectedReasonRequired = error => {
    const displayedError = DisplayService.getErrorResponse(
      error,
      'Cancel reason is required!',
    );

    toast.error(displayedError.message);
  };

  const sitterAction = status =>
    status === 'sitter_cancel' ||
    status === 'accepted' ||
    status === 'declined' ||
    status === 'sitter_reschedule' ||
    status === 'sitter_confirmed';

  const depositCompleteStatus = { code: 'deposit_complete' };
  const isDepositComplete =
    booking?.status?.code === 'closed_completed' &&
    booking?.paymentType === 'credit';

  const saveBooking = async () => {
    let userId = null;

    if (parentAction(booking.status.code)) {
      userId = booking.parent.id;
    } else if (sitterAction(booking.status.code)) {
      userId = booking.caregiver.id;
    }

    const status = isDepositComplete ? depositCompleteStatus : booking.status;

    if ((status.code === 'sitter_cancel' || status.code === 'parent_cancel') && !booking.rejectedReason) {
      rejectedReasonRequired('CancelReasonRequired');

      return;
    }

    const bookingData = {
      id: booking.id,
      start: DateUtil.getDateTimeString(
        moment(booking.start).set({ seconds: 0 }),
      ),
      end: DateUtil.getDateTimeString(moment(booking.end).set({ seconds: 0 })),
      status,
      paymentFrequency: booking.paymentFrequency,
      paymentType: booking.paymentType,
      rate: booking.rate,
      tip: booking.tip,
      total: booking.total,
      userId,
      adminCancellationReason: booking.adminCancellationReason,
      adminCancellationNote: booking.adminCancellationNote,
      rejectedReason: booking.rejectedReason,
      caregiverNote: booking.caregiverNote,
    };

    try {
      const bookingResponse = await BookingService.patch(
        booking.id,
        bookingData,
      );

      setBooking(bookingResponse.data.data);

      toast.success('Booking updated successfully');

      history.push(`/bookings/lookup?id=${booking.id}`);
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Paper className={classes.paper}>
      {booking && booking.id ? (
        <BookingEditForm booking={booking} setBooking={setBooking} />
      ) : (
        'Booking not found'
      )}
      <div className={classes.buttons}>
        <Button
          variant='contained'
          color='secondary'
          onClick={cancelEdit}
          className={classes.button}
        >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          onClick={saveBooking}
          className={classes.button}
        >
          Save
        </Button>
      </div>
    </Paper>
  );
}

export default withRouter(BookingEdit);
