import axios, { AxiosRequestConfig } from 'axios';
import { BookingNote , DataResponse, PagedResponse } from '../types';

const ADMIN_NOTE_URL = '/v2/booking-notes';

class BookingNoteService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<BookingNote>> {
    return axios.get(ADMIN_NOTE_URL, config);
  }

  static create(
    data: BookingNote,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<BookingNote>> {
    return axios.post(ADMIN_NOTE_URL, data, config);
  }

  static update(
    id: number,
    data: BookingNote,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<BookingNote>> {
    return axios.patch(`${ADMIN_NOTE_URL}/${id}`, data, config);
  }
}

export { BookingNoteService };
