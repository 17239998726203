import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse , AdminSystemSetting } from '../types';

const URL = '/v2/admin/system-settings';

class AdminSystemSettingService {
  static findByFilter(
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AdminSystemSetting>> {
    return axios.get(URL, config);
  }

  static update(
    id: number,
    data: AdminSystemSetting,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<AdminSystemSetting>> {
    return axios.patch(`${URL}/${id}`, data, config);
  }
}

export { AdminSystemSettingService };
