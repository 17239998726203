import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse, Reimbursement } from '../types';
import { Order } from '../types/order/Order';

const URL = '/v2/admin/order-reimbursement';

class ReimbursementOrderService {
  static reimburse(
    data: Order,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Order>> {
    return axios.post(URL, data, config);
  }

  static findAllByUserId(config?: AxiosRequestConfig): Promise<PagedResponse<Reimbursement>> {
    return axios.get(URL, config);
  }
}

export { ReimbursementOrderService };
