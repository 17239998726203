import React, { useEffect, useState } from 'react';
import {
  Button,
  Fade,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  TextField,
  Alert,
} from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

import { Reimbursement } from 'us-web-services';
import DateUtil from '../../../util/DateUtil';
import ModalStyles from '../../../styles/ModalStyles';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  reimbursement: Reimbursement;
  updateReimbursement: (reimbursement: number, reimbursementData: any) => void;
}

function ReimbursementEdit(props: Props) {
  const { open, setOpen, reimbursement, updateReimbursement } = props;
  const { classes } = ModalStyles();
  const [start, setStart] = useState(moment(reimbursement.startDate));
  const [end, setEnd] = useState(moment(reimbursement.endDate));
  const [selectedCareTypeCategory, setSelectedCareTypeCategory] = useState<string>(
    reimbursement.careTypeCategory || '',
  );
  const [careTypeCategories, setCareTypeCategories] = useState<string[]>([]);
  const [error, setError] = useState<string>('');

  const handleClose = () => {
    setOpen(false);
    setError('');
  };

  const getCareTypeCategories = async () => {
    const categories: string[] = [
      'Children - In-home',
      'Children - Daycare',
      'Children - Camps',
      'Children - Preschool',
      'Tutoring',
      'Elder Care',
      'Pet Care',
      'Household Services',
    ];

    setCareTypeCategories(categories);
  };

  const onCareTypeCategorySelect = (event: SelectChangeEvent<string>) => {
    setSelectedCareTypeCategory(event.target.value);
  };

  const validateDates = (): boolean => {
    const today = moment().startOf('day');

    if (start.isAfter(today) || end.isAfter(today)) {
      setError('Dates must be in the past');

      return false;
    }

    if (end.isBefore(start)) {
      setError('End date cannot be before start date');

      return false;
    }

    setError('');

    return true;
  };

  const saveReimbursement = async () => {
    if (!validateDates()) {
      return;
    }

    const reimbursementData = {
      id: reimbursement.usOonId,
      startDate: start.format(DateUtil.midnightDateFormat),
      endDate: end.format(DateUtil.midnightDateFormat),
      careTypeCategory: selectedCareTypeCategory,
    };

    updateReimbursement(reimbursement.usOonId, reimbursementData);
  };

  const handleStartDateChange = (newDate: moment.Moment | null) => {
    if (newDate) {
      setStart(newDate);
      setError('');
    }
  };

  const handleEndDateChange = (newDate: moment.Moment | null) => {
    if (newDate) {
      setEnd(newDate);
      setError('');
    }
  };

  useEffect(() => {
    getCareTypeCategories();
    setStart(moment(reimbursement.startDate));
    setEnd(moment(reimbursement.endDate));
    setSelectedCareTypeCategory(reimbursement.careTypeCategory || '');
    setError('');
  }, [reimbursement.id, reimbursement.endDate, reimbursement.startDate, reimbursement.careTypeCategory]);

  return (
    <Modal
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      className={classes.modal}
      closeAfterTransition
      onClose={handleClose}
      open={open}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id='plan-edit-modal-title'>Edit Out of Network</h2>

          {error && (
            <Alert severity='error' sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id='care-type-category-label'>Care type category</InputLabel>
                <Select
                  labelId='care-type-category-label'
                  id='care-type-category-select'
                  value={selectedCareTypeCategory}
                  onChange={onCareTypeCategorySelect}
                  label='Care type category'
                >
                  {careTypeCategories.map(item => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormLabel component='legend'>Start Date</FormLabel>
              <DatePicker
                inputFormat={DateUtil.dateReadable}
                onChange={handleStartDateChange}
                value={start}
                maxDate={moment().subtract(1, 'day')}
                renderInput={pickerProps => <TextField {...pickerProps} fullWidth />}
              />
            </Grid>

            <Grid item xs={12}>
              <FormLabel component='legend'>End Date</FormLabel>
              <DatePicker
                inputFormat={DateUtil.dateReadable}
                onChange={handleEndDateChange}
                value={end}
                maxDate={moment().subtract(1, 'day')}
                renderInput={pickerProps => <TextField {...pickerProps} fullWidth />}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                id='close'
                color='secondary'
                onClick={handleClose}
                variant='contained'
                sx={{ marginRight: 1 }}
              >
                Close
              </Button>
              <Button
                id='save'
                color='primary'
                onClick={saveReimbursement}
                variant='contained'
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </Fade>
    </Modal>
  );
}

export default ReimbursementEdit;
