import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, UsOon } from '../types';

const URL = '/v2/oon';

class UsOonService {
    static patch(
        id: number,
        data: UsOon,
        config?: AxiosRequestConfig
    ): Promise<DataResponse<UsOon>> {
        return axios.patch(`${URL}/${id}`, data, config);
    }
}
  
export { UsOonService };
  