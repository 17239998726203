import axios, { AxiosRequestConfig } from 'axios';
import { DataResponse, PagedResponse } from '../../types/api';
import { Agency } from '../../types/agency/Agency';

const URL = '/v2/agencies';

class AgencyService {
  static getByFilter(
    config?: AxiosRequestConfig
  ): Promise<PagedResponse<Agency>> {
    return axios.get(URL, config);
  }

  static getById(
    id: number,
    config?: AxiosRequestConfig
  ): Promise<DataResponse<Agency>> {
    return axios.get(`${URL}/${id}`, config);
  }
}
export { AgencyService };
